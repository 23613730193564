<template>
  <div class="mt-5">
    <b-container>
      <h2 class="text-primary font-size-20 font-weight-bold mb-3">
        {{ $t("main.searchResult") }}
      </h2>
      <b-row v-if="searchLoading">
        <b-col>
          <b-spinner label="Loading..."></b-spinner>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col v-if="!allProducts.length">
          <b-alert show variant="danger">
            <h5 class="font-weight-bold">
              {{ $t("main.noResult") }}
            </h5>
          </b-alert>
        </b-col>
        <b-col
          v-for="(product, key) in allProducts"
          class="col-md-6 col-lg-4 col-xl-3 mb-4"
          :key="key"
        >
          <transition
            name="router-anim"
            :enter-active-class="`animated zoomIn`"
            mode="out-in"
            :leave-active-class="`animated zoomOut`"
          >
            <productCard :productItem="product" :indexOfItem="key + 1" />
          </transition>
        </b-col>
      </b-row>
      <app-pagination :meta="meta" @input="getResult" />
    </b-container>
  </div>
</template>

<script>
import { core } from "@/config/pluginInit";
import api from "@/services/main.js";
import productCard from "@/modules/servicePages/marketplace/components/productCard.vue";
import profileProductsList from "@/modules/servicePages/marketplace/components/profile/profileProductsList";
import AppPagination from "@/components/appPagination.vue";
import commonMixin from "@/mixins/commonMixin";

export default {
  mixins: [commonMixin],
  components: { productCard, profileProductsList, AppPagination },
  data() {
    return {
      searchLoading: false,
      allProducts: [],
      meta: {},
    };
  },

  methods: {
    getResult(page = 1) {
      const { search } = this.$route.query;
      this.searchLoading = true;
      api
        .getGlobalSearch({ search, page })
        .then((res) => {
          this.allProducts = res.data.data;
          this.meta = res.data.meta;
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },
  },
  watch: {
    "$route.query.search": {
      handler: function (search) {
        this.getResult();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    core.index();
  },
};
</script>
<style lang="scss">
.search-listing {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background-color: #fff;
  border-radius: 4px;
  color: #333;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 20px;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 12px rgba(0 0 0 / 30%);
  }
  img {
    width: 100px;
    min-height: 100px;
    border-radius: 10px;
    display: block;
  }
}
</style>
