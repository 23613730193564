<template>
  <div class="d-flex justify-content-center">
    <b-pagination
      v-if="meta.pageCount > 1"
      v-model="meta.page"
      :total-rows="meta.total"
      :per-page="meta.take"
      first-number
      last-number
      class="mb-3 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      @input="page = $event"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    page: {
      get() {
        return this.meta.page;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>