<template>
  <iq-card class="d-flex overflow-hidden" style="min-height: 190px">
    <router-link
      :to="{ name: 'productDetails', params: { productId: productItem.id } }"
    >
      <div
        class="product-main-image"
        :style="{
          'background-image': 'url(' + productItem.featured_image + ')',
        }"
      ></div>
    </router-link>
    <router-link
      :to="{ name: 'productDetails', params: { productId: productItem.id } }"
      class="flex-grow-1 for-padding d-flex flex-column justify-content-between"
    >
      <div>
        <p class="product-name">{{ productItem.title }}</p>
        <p class="product-info">
          {{
            productItem.description.length >= 120
              ? productItem.description.slice(0, 120) + "...."
              : productItem.description
          }}
        </p>
      </div>
      <p class="last-price color-primary m-0 font-weight-bold font-1rem">
        {{ productItem.price }}
        <span class="text-primary font-weight-bold pr-1">
          {{ $t("main.sar") }}
        </span>
      </p>
    </router-link>
    <div>
      <div class="d-flex flex-column justify-content-between h-100 for-padding">
        <b-button
          v-if="!checkFavourite(productItem)"
          @click.prevent="addToFavourite(productItem)"
          class="heart-button"
        >
          <i class="las la-heart"></i>
        </b-button>
        <b-button
          v-else
          @click.prevent="deleteProductFavourite(productItem)"
          class="heart-favourite-button"
        >
          <i class="las la-heart"></i>
        </b-button>
        <!-- <b-button
          @click.prevent="addToCart(productItem)"
          variant="primary"
          class="d-flex justify-content-center align-items-center border-0 font-bold"
        >
          <span
            class="d-flex justify-content-center align-items-center font-2rem"
          >
            <i class="las la-cart-plus font-2rem"></i
          ></span>
        </b-button> -->
        <add-cart-icon :product="productItem" :quantity="1"></add-cart-icon>
      </div>
    </div>
  </iq-card>
</template>

<script>
// import { core } from '@/config/pluginInit'
import productFunctions from "@/mixins/productFunctions";
import addCartIcon from "./addCartIcon.vue";

export default {
  components: { addCartIcon },
  name: "profileProductsList",
  props: {
    inFav: {
      default: false,
    },
    productItem: {
      dafault: {},
    },
  },
  mixins: [productFunctions],
  data() {
    return {
      defaultImage: require("@/assets/images/ibbil/ibbil-cover.jpg"),
      title: "جلد طبيعى جلد خروف",
      description: "عبارة عن و صف التصنيع",
      price: "550",
      offer: "120.54",
      unit: "ر.س",
      rating: 5,
      discount: 16,
    };
  },
  computed: {
    totalPrice() {
      return this.productItem.tax
        ? (this.productItem.price * this.productItem.tax.percentage) / 100
        : this.productItem.price;
    },
    totalOfferPrice() {
      return this.productItem.tax
        ? (this.productItem.flash_sales[this.productItem.flash_sales.length - 1]
            .price *
            this.productItem.tax.percentage) /
            100
        : this.productItem.flash_sales[this.productItem.flash_sales.length - 1]
            .price;
    },
  },
  // mounted () {
  //   core.index()
  // }
};
</script>

<style lang="scss" scoped>
.product-card-button-list {
  border-radius: 0 10px 0 19px !important;
}
.iq-card {
  border-radius: 4px !important;
}
.product-main-image {
  width: 194px;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.product-main-image img {
  width: 100%;
  height: 100%;
}
.for-padding {
  padding: 1rem;
}
.product-name {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  color: #343a40 !important;
}

.product-info {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  color: #666666;
}

.price-offer {
  line-height: 1;
}

.last-price {
  line-height: 1;
}

.font-size-45 {
  font-size: 45px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.text-end {
  text-align: end !important;
}
.font-1rem {
  font-size: 1rem;
}
.font-2rem {
  font-size: 1.7rem;
}
.heart-button {
  border: none !important;
  background-color: #ededed !important;
  /*border-radius: 0 0 18px 0 !important;*/
  padding: 8px 20px !important;
  //width: 40%;
  margin-right: auto !important;
}

.heart-button i {
  color: #ffffff;
  font-size: 1.7rem;
  margin-left: 0 !important;
}

.heart-favourite-button {
  border: none !important;
  background-color: #ededed !important;
  /*border-radius: 0 0 18px 0 !important;*/
  padding: 8px 20px !important;
  //width: 40%;
  margin-right: auto !important;
}

.heart-favourite-button i {
  color: #d62020;
  font-size: 1.7rem;
  margin-left: 0 !important;
}

.product-cart-item {
  border-radius: 0px 10px 0 20px !important;
  padding: 13px 23px !important;
}

.font-size-30 {
  font-size: 30px !important;
}
.btn {
  border-radius: 4px !important;
  padding: 0.375rem 0.75rem !important;
  i {
    margin: 0 !important;
  }
}
</style>
